<template>
  <div>
    <div v-if="promotionCodes === null" class="text-center">
      <b-spinner />
    </div>
    <b-overlay v-else :show="isFetching">
      <h6>
        {{ `${$t('fields.result')} (${total})` }}
      </h6>
      <b-row align-v="center" class="mb-3">
        <b-col cols="12" md="4">
          <LimitSelect v-model="paginationPayload.limit" />
        </b-col>
        <b-col cols="12" md="8">
          <b-row>
            <b-col>
              <b-input-group>
                <b-form-input
                    v-model="paginationPayload.search"
                    :placeholder="`${$t('fields.search')}...(ชื่อ/ โค้ด) `"
                    class="mx-1"
                    type="search"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col v-if="isOwner">
              <MasterSelectInput
                  :value="selectedMaster"
                  hide-label
                  @update="onMasterIdChange"
              />
            </b-col>
            <b-col v-if="isOwner || isMaster">
              <AgentsSelectInput
                  :master-id="selectedMaster"
                  :value="selectedAgent"
                  hide-label
                  @update="onAgentIdChange"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-table :fields="fields" :items="promotionCodes.items" responsive show-empty>
        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(isOncePerUser)="data">
          <span v-if="data.value" class="badge badge-warning">
            {{ $t('promotion_code.one_time') }}
          </span>
          <span v-else class="badge badge-info">
            {{ $t('promotion_code.many_time') }}
          </span>
        </template>

        <template #cell(count)="data">
          <span class="badge badge-success">
            {{ data.value }}
            <span v-if="data.item.maxReceive">/ {{ data.item.maxReceive }}</span>
            ครั้ง
          </span>
        </template>

        <template #cell(id)="data">
          <b-button-group
              v-if="$allowPermission('agent:manage.promotion-code')"
          >
            <b-button
                :to="`/promotion-code/${data.value}`"
                class="btn btn-info btn-sm"
            >
              {{ $t('buttons.edit') }}
            </b-button>
            <button
                class="btn btn-danger btn-sm"
                type="button"
                @click="onRemovePromotion(data.value)"
            >
              {{ $t('buttons.remove') }}
            </button>
          </b-button-group>
        </template>
        <template #empty="">
          <p class="text-center text-muted">{{
              $t('messages.nothing_here')
            }}</p>
        </template>
      </b-table>
      <PaginationInput :per-page="paginationPayload.limit" :total="total" @update="(val) => paginationPayload.page = val"/>
    </b-overlay>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';

export default {
  name: 'PromotionCodeList',
  components: {
    MasterSelectInput: () => import('@components/master/master-select-input'),
    AgentsSelectInput: () => import('@components/agents/agents-select-input'),
  },
  data() {
    return {
      isFetching: false,
      selectedAgent: '',
      selectedMaster: '',
      paginationPayload: {
        page: 1,
        limit: 20,
        search: '',
        agentId: null,
        masterId: null
      },
      fields: [
        '#',
        {
          key: 'name',
          label: this.$t('promotion_code.name')
        },
        {
          key: 'code',
          label: this.$t('promotion_code.code')
        },
        {
          key: 'isOncePerUser',
          label: this.$t('promotion_code.usage')
        },
        {
          key: 'count',
          label: this.$t('promotion_code.count')
        },
        {
          key: 'id',
          label: '',
          class: 'text-right'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      promotionCodes: (state) => state['promotion-code'].list
    }),
    ...mapGetters(['isOwner', 'isMaster', 'userInfo']),
    pagination() {
      return this.promotionCodes?.meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: {
    'paginationPayload.page'(val) {
      if(val){
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    'paginationPayload.search'() {
      this.delaySearch()
    },
    'paginationPayload.limit'(val) {
      if(val) {
        this.paginationPayload.page > 1 ? this.paginationPayload.page = 1 : this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchPromotionCodes', 'deletePromotionCode']),
    async fetchData() {
      this.isFetching = true
      await this.fetchPromotionCodes(this.paginationPayload)
      this.isFetching = false
    },
    delaySearch() {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(this.fetchData, 800)
    },
    onAgentIdChange(agentId) {
      this.paginationPayload.agentId = agentId
      this.fetchData()
    },
    onMasterIdChange(masterId) {
      this.paginationPayload.masterId = masterId
      this.fetchData()
    },
    async onRemovePromotion(id) {
      const isConfirm = await this.$bvModal
          .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
            okTitle: `${this.$t('buttons.confirm')}`,
            cancelTitle: `${this.$t('buttons.cancel')}`,
          })
      if (isConfirm) {
        this.isFetching = true
        await this.deletePromotionCode(id)
        await this.fetchData()
      }
    }
  }
}
</script>

<style scoped>

</style>